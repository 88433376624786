
import './App.css';

import NavLayout from './Layout/NavLayout';

function App() {

  return (
    <div className="App">
      {<NavLayout />}
    </div>
  );
}

export default App;
